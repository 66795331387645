<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #E6E6E6;
    "
  >
    <v-card class="px-4">
      <v-data-table
        :headers="headers"
        :items="trapoin"
        :items-per-page="25"
        class="elevation-0"
        mobile-breakpoint="100"
        hide-default-footer
      >
        <template v-slot:top>
          <v-row class="mb-6">
            <v-col
              cols="12"
              sm="6"
              style="
              display: flex;
              align-items: center;
            "
            >
              <span class="px-2 font-common">
                Periode
              </span>

              <date-picker
                v-model="start_date"
                class="px-2"
                placeholder="Tgl Awal"
                value-type="format"
                format="YYYY-MM-DD"
              />

              <span> - </span>

              <date-picker
                v-model="end_date"
                class="px-2"
                placeholder="Tgl Akhir"
                value-type="format"
                format="YYYY-MM-DD"
              />

              <v-btn
                class="px-2"
                small
                style="
                background: #7A1878;
                border: none;
                outline: none;
              "
                @click="filterByDate"
              >
                <span class="text-capitalize">
                  Lihat
                </span>
              </v-btn>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              style="
              justify-content: space-between;
              display: flex;
            "
            >
              <div
                class="d-flex ml-4 pl-4"
                style="flex-direction: column"
              >
                <span
                  class="font-common"
                  style="color: #7A1878"
                >
                  Trapoin Diterbitkan
                </span>
                <span
                  class="font-common-bold"
                  style="
                  font-size: 24px;
                  color: #7A1878;
                "
                >
                  {{ 'Rp. ' + recap.aktif | dotThousandSeparator }}
                </span>
              </div>

              <div
                class="d-flex mr-4 pr-4"
                style="flex-direction: column"
              >
                <span
                  class="font-common"
                  style="color: #EA3E3A"
                >
                  Trapoin Terpakai
                </span>
                <span
                  class="font-common-bold"
                  style="
                  font-size: 24px;
                  color: #EA3E3A;
                "
                >
                  {{ 'Rp. ' + recap.terpakai | dotThousandSeparator }}
                </span>
              </div>
            </v-col>
          </v-row>
        </template>

        <template v-slot:item.nominal="{ item }">
          <div
            :style="item.nominal < 0 ? 'color: #EA3E3A' : 'color: #5DB025'"
            style="
            display: flex;
            justify-content: space-between;
          "
          >
            <span>
              Rp
            </span>

            <span>
              {{ item.nominal | dotThousandSeparator }}
            </span>
          </div>
        </template>

        <template v-slot:item.description="{ item }">
          <span v-if="item.description === null || item.description === ''">
            -
          </span>
          <span v-else>
            {{ item.description }}
          </span>
        </template>

        <template v-slot:footer>
          <div class="text-center my-5 py-3">
            <v-pagination
              v-model="page"
              :length="pageCount"
              color="purple"
              :total-visible="5"
              @input="onChangePage"
            />
            <!-- <span style="font-size: 12px; font-style: italic;">
              Page {{ page }} of {{ pageCount || 1 }}
            </span> -->
            <span style="font-size: 12px; font-style: italic;">
              {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
            </span>
          </div>
          <!-- <v-row class="mt-n16">
            <v-col class="text-right pr-8">
              <span style="font-size: 12px; font-style: italic;">
                {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
              </span>
            </v-col>
          </v-row> -->
        </template>
      </v-data-table>
    </v-card>

    <!-- DIALOG -->
    <v-dialog
      v-model="dialog"
      max-width="600"
    >
      <v-card class="px-4 py-4" />
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  import DatePicker from 'vue2-datepicker'
  import 'vue2-datepicker/index.css'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    components: {
      'date-picker': DatePicker,
    },

    data: () => ({
      dialog: false,

      // Trapoin:
      search: '',
      headers: [
        { text: 'Nama', value: 'user_name', sortable: false },
        { text: 'Tipe', value: 'user_type' },
        { text: 'Nominal', value: 'nominal' },
        { text: 'Tanggal Transaksi', value: 'trx_date' },
        { text: 'Keterangan', value: 'description' },
      ],
      trapoin: [],

      start_date: '',
      end_date: '',

      recap: {
        aktif: 0,
        terpakai: 0,
      },

      page: 1,
      pageCount: 0,
      itemCount: 0,
      itemTotal: 0,
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),
    },

    watch: {
    },

    created () {
      this.initialize(1, this.itemsPerPage, '', '', '')
      // this.getPerusahaan(1, this.itemsPerPage)
    },

    methods: {
      initialize (page, limit, filter, sd, ed) {
        // Check user menus
        // const userMenus = localStorage.getItem('userMenus').split(',')

        // if (userMenus.includes('CUSTOMER_VERIFICATION')) {
        //   this.isAllowVerification = true
        // }

        const vm = this

        const requestBody = {
          page: page,
          limit: limit,
          searchq: filter,
          date_start: sd,
          date_end: ed,
        }

        axios.post('/v2/t/wpoint-global', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.trapoin = res.data.data.list
            vm.recap = res.data.data.recap

            vm.pageCount = res.data.data.pagination.page_total
            vm.itemCount = res.data.data.pagination.item_count
            vm.itemTotal = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      onChangePage (e) {
        this.initialize(e, this.itemsPerPage)
      },

      filterByDate () {
        // console.log(this.start_date)
        this.initialize(1, this.itemsPerPage, '', this.start_date, this.end_date)
      },

      filterData () {
        this.initialize(1, this.itemsPerPage, this.search)
      },

      filterClear () {
        this.initialize(1, this.itemsPerPage)
      },
    },
  }
</script>

<style scoped>
.font-common {
  font-family: Lato;
  font-size: 16px;
  color: #808080;
}

.font-common-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #808080;
}

.font-common-black {
  font-family: Lato;
  font-size: 16px;
  color: #333333;
}

.font-common-black-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.font-common-white {
  font-family: Lato;
  font-size: 16px;
  color: #FFFFFF;
}

.font-common-white-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
}

#action-container {
  background: #FFFFFF;
}

/* #action-container:hover ~ #action-icon {
  background: #7A1878;
  color: #FFFFFF;
} */

#action-icon {
  color: #7A1878;
}

/* #action-icon:hover {
  color: #FFFFFF;
} */

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
